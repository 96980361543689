export const AbTestingExperimentAuthorFragment = `
  fragment AbTestingExperimentAuthorFragment on AbTestingExperimentAuthor {
    id
    name
    email
  }
`;

export const AbTestingExperimentFragment = `
  fragment AbTestingExperimentFragment on RetailAbTestingExperimentProjection {
    condition
    environment
    enabled
    expireAt
    gaExperimentId
    id
    multiStep
    name
    nextStepVariants {
      id
      nextStep
      skipSteps
      weightPercents
    }
    startAt
    variants {
      id
      viewParams
      viewVariant
      weightPercents
    }
    viewNames
    trafficPercents
    createdBy {
      ...AbTestingExperimentAuthorFragment
    }
    createdOn
    updatedBy {
      ...AbTestingExperimentAuthorFragment
    }
    updatedOn
    team
  }
  ${AbTestingExperimentAuthorFragment}
`;

export const AbTestingExperimentCountersFragment = `
  fragment AbTestingExperimentCountersFragment on RetailAbTestingExperimentCountersProjection {
    counters
    totalHits
  }
`;
