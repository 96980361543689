import { ESCAPE_SYMBOL, FilterType } from "constants/filter";
import { flow, replace } from "lodash/fp";

const escapeForLikeFilter = flow(
  replace(/[_%\\]/g, `${ESCAPE_SYMBOL}$&`),
  (v) => `%${v}%`
);

export function createBaseFilter(
  field: string | null,
  op: typeof FilterType[keyof typeof FilterType],
  value?: string
) {
  return {
    field,
    op,
    value,
  };
}

export const createFilter = (
  field: string | null,
  op: typeof FilterType[keyof typeof FilterType],
  value?: string
) => {
  if (op === FilterType.LIKE) {
    const escapedValue = value ? escapeForLikeFilter(value) : value;

    return {
      field,
      op,
      value: escapedValue,
      escape: ESCAPE_SYMBOL,
    };
  }

  return createBaseFilter(field, op, value);
};
