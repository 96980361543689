import { useMemo, useCallback } from "react";
import { useQuery } from "react-query";
import { Config, ImmutableTree, Utils } from "react-awesome-query-builder";
import { ConditionVar } from "gql";
import { LIST_CONDITION_VARS } from "gql/queries";
import {
  KnownConditionVar,
  KnownVarOperator,
  STATUS,
  KNOWN_VAR_OPERATORS,
} from "../types";
import { makeBuilderConfig } from "../builderConfig";

export interface UseConditionBuilderOutput {
  state: STATUS.PENDING | STATUS.READY;
  builderConfig?: Config;
  makeCondition?: (input?: null | string) => ImmutableTree;
  stringifyCondition?: (input: ImmutableTree) => null | string;
}

const emptyCondition = { and: [] };

export const useConditionBuilder = (): UseConditionBuilderOutput => {
  const { data: { vars } = {} } = useQuery<Record<"vars", ConditionVar[]>>(
    LIST_CONDITION_VARS,
    { refetchOnWindowFocus: false }
  );

  const builderConfig = useMemo(() => {
    const knownVarOperators = new Set(KNOWN_VAR_OPERATORS);
    const knownVars = vars
      ?.map((variable) => ({
        ...variable,
        type: variable.type?.toUpperCase(),
      }))
      .filter<KnownConditionVar>((variable): variable is KnownConditionVar =>
        knownVarOperators.has(variable.type as KnownVarOperator)
      );

    return knownVars ? makeBuilderConfig(knownVars) : null;
  }, [vars]);

  const makeCondition = useCallback(
    (input?: null | string): ImmutableTree =>
      Utils.loadFromJsonLogic(
        input ? JSON.parse(input) : emptyCondition,
        builderConfig
      ),
    [builderConfig]
  );

  const stringifyCondition = useCallback(
    (input: ImmutableTree): null | string => {
      const { logic, errors } = Utils.jsonLogicFormat(input, builderConfig);

      if (errors?.length) {
        throw new Error(`Invalid condition value: ${errors.join(", ")}`);
      }

      return logic ? JSON.stringify(logic, undefined, 2) : null;
    },
    [builderConfig]
  );

  if (!builderConfig) {
    return { state: STATUS.PENDING };
  }

  return {
    state: STATUS.READY,
    builderConfig,
    makeCondition,
    stringifyCondition,
  };
};
