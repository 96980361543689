import { useQuery } from "react-query";

import { GET_AVAILABLE_TEAMS } from "gql/queries";
import { AvailableTeamType } from "gql";

import { config } from "config";

interface AvailableTeamsQueryType {
  getAvailableTeams: AvailableTeamType[];
}

export const useAvailableTeams = (): {
  availableTeams: AvailableTeamType[];
  isLoading: boolean;
} => {
  const { data, isLoading } = useQuery<AvailableTeamsQueryType>([
    GET_AVAILABLE_TEAMS,
    {
      environment: config.APP_ENV,
    },
  ]);

  return {
    availableTeams: data?.getAvailableTeams || [],
    isLoading,
  };
};
