import { VALIDATE_NAME_MS_DELAY } from "constants/delay";
import { useExperimentsByGaExpIdCount } from "pages/editing/hooks/useExperimentsByGaExpIdCount";
import { FC, useRef, useEffect, ChangeEvent, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Button, Col, Space, message, Form, Divider } from "antd-4.21.7";
import moment from "moment";
import { Utils } from "react-awesome-query-builder";
import {
  AbTestingExperimentFormValues,
  ExperimentTypeName,
} from "pages/editing/types";
import { useDebounce } from "hooks/useDebounce";
import { useSearchExperimentsData } from "pages/editing/hooks/useSearchExperiments";
import { createFormController } from "controls/helpers/createFormController";
import { CustomInputFormController } from "controls/Input";
import CustomSelectFormController from "controls/Select/index";
import { CustomDatePickerFormController } from "controls/DatePicker";
import { CustomRadioGroupFormController } from "controls/RadioGroup";
import { ConditionBuilder } from "components/ConditionBuilder";
import {
  EXPERIMENT_TYPE_OPTIONS,
  VIEW_NAME_OPTIONS,
  EXPERIMENT_TYPE_PROPS_MAPPING,
  EXPERIMENT_TYPE_LABELS,
  CHECKOUT_VIEW_NAMES,
  FINANCING_VIEW_NAMES,
} from "./config";
import { BackButton } from "../BackButton/BackButton";
import { AbTestFormType } from "./types";
import { VariantFields } from "../VariantFields";
import { validationResolver } from "./validation";
import { useExperimentRelatedData } from "../../hooks/useExperimentRelatedData";
import {
  ClipboardSaveButton,
  ClipboardPasteButton,
  ClipboardInfoButton,
  hasClipboardAPI,
} from "./ClipboardButtons";
import { DeleteParticipantsForm } from "../DeleteParticipantsForm";
import { useDeleteParticipants } from "pages/editing/hooks/useDeleteParticipants";
import { DEFAULT_MESSAGE_STYLES } from "constants/message";
import {
  ClearOutlined,
  EditOutlined,
  PlusOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import { formItemTailLayout } from "controls/ControlGroup/ControlGroup";
import { ACL, PERMISSIONS } from "components/ACL";
import { generateExperimentId } from "./utils";
import { useAvailableTeams } from "../../hooks/useAvailableTeams";

export interface ABTestFormProps {
  actionType: AbTestFormType;
  formValues: AbTestingExperimentFormValues;
  onSubmit: (experiment: any) => void;
}

const useExperimentsCount = (value: string) => {
  const { id: experimentId } = useParams();
  const debouncedValue = useDebounce(value, VALIDATE_NAME_MS_DELAY);
  const { refetch, totalCount } = useSearchExperimentsData(
    {
      name: debouncedValue,
      excludeId: experimentId,
    },
    !!debouncedValue
  );

  useEffect(() => {
    if (debouncedValue) {
      refetch();
    }
  }, [debouncedValue, refetch]);

  return totalCount;
};

const ConditionBuilderController = createFormController({
  Control: ConditionBuilder,
});

export const ABTestForm: FC<ABTestFormProps> = ({
  actionType,
  formValues,
  onSubmit,
}) => {
  const form = useForm({
    mode: "onBlur",
    resolver: validationResolver,
    defaultValues: {
      ...(formValues as any),
      autogeneratedGaExperimentId: actionType !== "create",
    },
  });
  const [
    nameFormValue,
    experimentTypeFormValue,
    viewNamesFormValue,
    autogenerateGaExpIdFormValue,
    gaExperimentId,
  ] = form.watch([
    "name",
    "experimentType",
    "viewNames",
    "autogeneratedGaExperimentId",
    "gaExperimentId",
  ]);
  const experimentsCount = useExperimentsCount(nameFormValue);
  const gaExperimentsIds = useExperimentsByGaExpIdCount(gaExperimentId);
  const experimentRelatedData = useExperimentRelatedData();
  const { availableTeams } = useAvailableTeams();

  const onDeleteParticipantsComplete = useCallback((error, deletedCount) => {
    if (!error) {
      if (deletedCount > 0) {
        message.success({
          content:
            deletedCount > 1
              ? `${deletedCount} participants were successfully deleted`
              : `1 participant was successfully deleted`,
          style: DEFAULT_MESSAGE_STYLES,
        });
      } else {
        message.warning({
          content: "Participant not found, nothing to delete",
          style: DEFAULT_MESSAGE_STYLES,
        });
      }
    } else {
      message.error({
        content: error,
        style: DEFAULT_MESSAGE_STYLES,
      });
    }
  }, []);

  const { deleteParticipants } = useDeleteParticipants(
    nameFormValue,
    onDeleteParticipantsComplete
  );

  const viewNameOptions = {
    [ExperimentTypeName.SIMPLE]: VIEW_NAME_OPTIONS,
    [ExperimentTypeName.MULTI_STEP]:
      experimentRelatedData.multiStepExperimentViewNameOptions,
  };

  const previousViewNamesRef = useRef({
    [ExperimentTypeName.SIMPLE]: [VIEW_NAME_OPTIONS[0].value],
  });

  previousViewNamesRef.current[experimentTypeFormValue] = viewNamesFormValue;

  const isDisabled = actionType === "view";
  const { isDirty, errors } = form.formState;

  useEffect(() => {
    if (experimentRelatedData.multiStepExperimentViewNameOptions?.length) {
      previousViewNamesRef.current[ExperimentTypeName.MULTI_STEP] = [
        experimentRelatedData.multiStepExperimentViewNameOptions[0].value,
      ];
      previousViewNamesRef.current[experimentTypeFormValue] =
        viewNamesFormValue;
    }
  }, [
    experimentRelatedData.multiStepExperimentViewNameOptions,
    experimentTypeFormValue,
    viewNamesFormValue,
  ]);

  useEffect(() => {
    if (!formValues.isPlaceholder) {
      form.reset(formValues);
    }
  }, []);

  const handleIsAutogenerateGaExpIdChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const isAutogenerate = event.target.checked;
    form.setValue("autogeneratedGaExperimentId", isAutogenerate, {
      shouldValidate: true,
      shouldDirty: true,
    });
    if (isAutogenerate) {
      form.setValue("gaExperimentId", generateExperimentId(), {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      form.resetField("gaExperimentId");
    }
  };

  const handleExperimentTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const experimentType = event.target.value;
    form.setValue("viewNames", previousViewNamesRef.current[experimentType], {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleStartAtChange = (value) => {
    if (!value) {
      form.setValue("startAt", moment().startOf("day"));
    }
    form.trigger("expireAt");
  };

  const handleExpireAtChange = () => {
    form.trigger("startAt");
  };

  const handleViewNameChange = (value) => {
    if (experimentTypeFormValue === ExperimentTypeName.MULTI_STEP) {
      form.setValue("viewNames", [value], {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    previousViewNamesRef.current[experimentTypeFormValue] = Array.isArray(value)
      ? value
      : [value];
  };

  const setViewNames = (values: string[]) => {
    if (experimentTypeFormValue === ExperimentTypeName.SIMPLE) {
      form.setValue("viewNames", values, {
        shouldValidate: true,
        shouldDirty: true,
      });
      handleViewNameChange(values);
    }
  };

  const handlePasteFromClipboard = (payload) => {
    const { name, startAt, expireAt, condition, ...data } = payload;
    const conditionTree = Utils.loadTree(condition);

    form.setValue("name", name, { shouldDirty: true });
    form.reset(
      {
        name,
        startAt: startAt ? moment(startAt) : undefined,
        expireAt: expireAt ? moment(expireAt) : undefined,
        condition: conditionTree,
        ...data,
      },
      {
        keepDirty: true,
      }
    );
  };

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CustomInputFormController
            {...(experimentsCount >= 1 && !errors["name"]
              ? {
                  hasFeedback: true,
                  validateStatus: "warning",
                  help: `This experiment is part of a group of ${
                    experimentsCount + 1
                  } experiments`,
                }
              : {})}
            required
            label="Name"
            name="name"
            type="text"
            dataQa="name"
            isDisabled={isDisabled}
          />

          <CustomInputFormController
            label="Autogenerate GA Experiment ID"
            name="autogeneratedGaExperimentId"
            type="checkbox"
            dataQa="autogenerated-ga-experiment_id"
            isDisabled={actionType !== "create"}
            onChange={handleIsAutogenerateGaExpIdChange}
          />

          <CustomInputFormController
            label="GA Experiment ID"
            name="gaExperimentId"
            type="text"
            dataQa="ga-experiment_id"
            isDisabled={actionType !== "create" || autogenerateGaExpIdFormValue}
            {...(!!gaExperimentsIds.length && !errors["gaExperimentId"]
              ? {
                  hasFeedback: true,
                  validateStatus: "warning",
                  help: `This experiment is part of a group of ${
                    gaExperimentsIds.length + 1
                  } experiments`,
                }
              : {})}
          />

          <CustomSelectFormController
            label="Responsible Team"
            name="team"
            type="text"
            dataQa="team"
            isDisabled={isDisabled}
            options={availableTeams.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
          />

          <CustomDatePickerFormController
            label="Start At"
            name="startAt"
            dataQa="start-at"
            onChange={handleStartAtChange}
            isDisabled={isDisabled}
            required
          />

          <CustomDatePickerFormController
            label="Expire At"
            name="expireAt"
            dataQa="expire-at"
            isDisabled={isDisabled}
            onChange={handleExpireAtChange}
          />

          <CustomInputFormController
            label="Enabled"
            name="enabled"
            type="checkbox"
            dataQa="enabled"
            isDisabled={isDisabled}
          />

          <CustomRadioGroupFormController
            label="Type"
            name="experimentType"
            options={EXPERIMENT_TYPE_OPTIONS}
            dataQa="experiment-type"
            isDisabled={isDisabled}
            onChange={handleExperimentTypeChange}
            tooltip={`${
              EXPERIMENT_TYPE_LABELS[ExperimentTypeName.SIMPLE]
            } - add a new variation or parameters to one or more pages, ${
              EXPERIMENT_TYPE_LABELS[ExperimentTypeName.MULTI_STEP]
            } - add new checkout steps, swap or remove existing ones`}
          />

          <CustomSelectFormController
            {...EXPERIMENT_TYPE_PROPS_MAPPING[experimentTypeFormValue]}
            name="viewNames"
            type="text"
            dataQa="view-names"
            isDisabled={isDisabled}
            options={viewNameOptions[experimentTypeFormValue]}
            onChange={handleViewNameChange}
            required
          />

          {experimentTypeFormValue === ExperimentTypeName.SIMPLE && (
            <Form.Item {...formItemTailLayout}>
              <Space size="small">
                <Button
                  data-qa-selector="add-checkout-view-names"
                  type="default"
                  htmlType="button"
                  icon={<SelectOutlined />}
                  onClick={() => setViewNames(CHECKOUT_VIEW_NAMES)}
                >
                  Checkout Steps
                </Button>

                <Button
                  data-qa-selector="add-financing-view-names"
                  type="default"
                  htmlType="button"
                  icon={<SelectOutlined />}
                  onClick={() => setViewNames(FINANCING_VIEW_NAMES)}
                >
                  Financing Steps
                </Button>

                <Button
                  data-qa-selector="clear-view-names"
                  type="default"
                  htmlType="button"
                  icon={<ClearOutlined />}
                  danger
                  onClick={() => setViewNames([])}
                >
                  Clear
                </Button>
              </Space>
            </Form.Item>
          )}

          <ConditionBuilderController
            label="Condition"
            name="condition"
            dataQa="conditions"
            isDisabled={isDisabled}
          />

          <CustomInputFormController
            label="Traffic Percents"
            name="trafficPercents"
            type="number"
            min="0"
            max="100"
            dataQa="ga-traffic_percents"
            isDisabled={isDisabled}
            required
          />

          {experimentTypeFormValue === ExperimentTypeName.SIMPLE && (
            <VariantFields name="variants" isDisabled={isDisabled}>
              <VariantFields.CountField
                data-qa-selector="variants"
                title="Variants"
              />
            </VariantFields>
          )}

          {experimentTypeFormValue === ExperimentTypeName.MULTI_STEP && (
            <VariantFields name="nextStepVariants" isDisabled={isDisabled}>
              <VariantFields.CountField
                data-qa-selector="next-step-variants"
                title="Variants"
              />
            </VariantFields>
          )}

          <Form.Item {...formItemTailLayout}>
            <Space size="small">
              {hasClipboardAPI && (
                <>
                  <Col>
                    <ClipboardPasteButton onPaste={handlePasteFromClipboard} />
                  </Col>
                  <Col>
                    <ClipboardSaveButton getValues={form.getValues} />
                  </Col>
                  <Col>
                    <ClipboardInfoButton />
                  </Col>
                </>
              )}
            </Space>
          </Form.Item>

          <Form.Item {...formItemTailLayout}>
            <Space size="small">
              {!isDisabled && (
                <Col>
                  <Button
                    data-qa-selector="create-experiment"
                    disabled={!isDirty}
                    type="primary"
                    htmlType="submit"
                    icon={
                      actionType === "edit" ? (
                        <EditOutlined />
                      ) : (
                        <PlusOutlined />
                      )
                    }
                  >
                    {`${
                      actionType === "edit" ? "Update" : "Create"
                    } Experiment`}
                  </Button>
                </Col>
              )}
              <BackButton dataQa="back-to-list" />
            </Space>
          </Form.Item>
        </form>
      </FormProvider>

      <ACL allow={PERMISSIONS.DELETE_PARTICIPANTS}>
        <>
          <Divider />
          <DeleteParticipantsForm
            actionType={actionType}
            onSubmit={(deleteParticipantsData) =>
              deleteParticipants(deleteParticipantsData)
            }
          />
        </>
      </ACL>
    </>
  );
};
