import memoizeOne from "memoize-one";

import { AbTestingExperiment } from "gql";

import { useConditionBuilder, STATUS } from "components/ConditionBuilder";
import { createExperimentFormValues } from "../utils/experimentFormValues";
import { useAvailableTeams } from "./useAvailableTeams";

interface IUseInitialFormDataOptions {
  experiment?: AbTestingExperiment;
  isExperimentLoading?: boolean;
}

const createExperimentFormValuesMemoized = memoizeOne(
  createExperimentFormValues
);

export const useInitialFormValues = (options?: IUseInitialFormDataOptions) => {
  const conditionBuilder = useConditionBuilder();
  const { isLoading, availableTeams } = useAvailableTeams();
  const loading =
    isLoading ||
    conditionBuilder.state === STATUS.PENDING ||
    options?.isExperimentLoading;

  if (loading) return null;
  return createExperimentFormValuesMemoized(
    conditionBuilder.makeCondition,
    availableTeams,
    options?.experiment
  );
};
