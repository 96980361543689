import { FilterType } from "constants/filter";
import { AbTestingExperiment } from "gql";
import { SEARCH_EXPERIMENTS } from "gql/queries";
import { createFilter } from "helpers/createFilter";
import { useDebounce } from "hooks/useDebounce";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { VALIDATE_NAME_MS_DELAY } from "constants/delay";

interface SearchExperimentsResult {
  searchAbTestingExperiments: { entities: AbTestingExperiment[] };
}

export const useExperimentsByGaExpIdCount = (gaExpId: string) => {
  const { id: experimentId } = useParams();
  const debouncedGaExpId = useDebounce(gaExpId, VALIDATE_NAME_MS_DELAY);
  const { data } = useQuery<SearchExperimentsResult>(
    [
      SEARCH_EXPERIMENTS,
      {
        search: {
          filter: createFilter(
            "gaExperimentId",
            FilterType.EQUAL,
            debouncedGaExpId
          ),
        },
      },
    ],
    { enabled: !!debouncedGaExpId?.length }
  );

  return (
    data?.searchAbTestingExperiments.entities?.filter(
      ({ id }) => id !== experimentId
    ) || []
  );
};
