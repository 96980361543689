import { Moment } from "moment";
import { ImmutableTree } from "react-awesome-query-builder";
import { AbTestingNextStepVariant, AbTestingVariant } from "gql/index";

export enum ExperimentTypeName {
  SIMPLE = "simple",
  MULTI_STEP = "multi",
}

export type ExperimentType =
  | ExperimentTypeName.SIMPLE
  | ExperimentTypeName.MULTI_STEP;

export type AbTestingExperimentFormValues = {
  name: string;
  gaExperimentId?: string;
  startAt: Moment;
  expireAt?: Moment;
  enabled: boolean;
  experimentType: ExperimentType;
  viewNames: string[];
  condition: ImmutableTree;
  trafficPercents: number;
  variants?: AbTestingVariant[];
  variantsNumber: number;
  nextStepVariants?: AbTestingNextStepVariant[];
  nextStepVariantsNumber: number;
  environment?: string;
  isPlaceholder?: boolean;
  team: string | null;
};

export interface SelectOption {
  label?: string;
  value: string;
}

export interface ExperimentAuthorItem {
  id: string;
  name: string;
  email: string;
  date: string;
  title: string;
}
