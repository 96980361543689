export const ESCAPE_SYMBOL = "\\";
export const FilterType = {
  OR: "or",
  AND: "and",
  NOT: "not",
  EQUAL: "eq",
  NOT_EQUAL: "ne",
  IN: "in",
  LIKE: "like",
  IS_NULL: "is_null",
  IS_NOT_NULL: "is_not_null",
  LESS_OR_EQUAL: "le",
  LESS_THAN_OR_EQUAL: "lte",
  LESS_THAN: "lt",
  GREATER_OR_EQUAL: "ge",
  GREATER_THAN_OR_EQUAL: "gte",
  GREATER_THAN: "gt",
};
